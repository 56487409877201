body {
  margin: 0px;
  background-color: rgb(33%, 35%, 35%);
  color: white;
  text-align: center;
}

.row {
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
  text-align: center;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  width: 600px;
  margin: auto;
}

.status-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: auto;
  width: 200px;
  height: 300px;
  border-radius: 5px;
  background-color: rgba(255,255,255,0.9);
  color: black;
  box-shadow: 5px 5px 2px 1px rgba(0,0,0,0.3);
  /* border: 1px solid black; */
}

.hover {
  background-color: rgba(0,0,0,0.1);
}
.hover :hover {
  background-color: rgba(255,255,255,1);
}
.logo {
  padding: 10px;
}
.logo :hover {
  background-color: rgba(0,0,0,0);
}
#timeout {
  background-color: rgb(248, 128, 47);
}

#live {
  background-color: palegreen;
}

.timecode {
  margin-bottom: 10px;
}
.twitter {
  margin-left: auto;
  margin-right: auto;
}
.twitterName {
  color:#1DA1F2;
  /* color: inherit; */
  text-decoration: none;
}
.twitterName:hover {
  color: white;
}
#logo-img {
  width: 100px;
  height: 100px;


}